import { createRouter, createWebHashHistory } from 'vue-router'
const Layout = () => import('@/views/Layout')
const Home = () => import('@/views/Home/index')
const Goods = () => import('@/views/Goods/index')
const AllGame = () => import('@/views/AllGame/index')
const Wares = () => import('@/views/Wares/index')
const Pay = () => import('@/views/Pay/index')
const Retrieve = () => import('@/views/Retrieve/index')
const Appraisal = () => import('@/views/Appraisal/index')
const User = () => import('@/views/User/index')
const SelectRegion = () => import('@/views/SelectRegion/index')
const SellForm = () => import('@/views/SellForm/index')
const examine = () => import('@/views/examine/index')
const identifyPay = () => import('@/views/identifyPay/index')
const AppraisalForm = () => import('@/views/AppraisalForm/index')
const identResults = () => import('@/views/identResults/index')
const autInfo = () => import('@/views/autInfo/index')
const article = () => import('@/views/article/index')
const order = () => import('@/views/order/index')
const download = () => import('@/views/download/index')
const service = () => import('@/views/service/index')
const BidSelectRegion = () => import('@/views/BidSelectRegion/index')
const BidForm = () => import('@/views/BidForm/index')
const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      { path: '/', component: Home },
      // 增加分类路由绑定
      { path: '/product/:id', name: 'product' },
      { path: '/goods', component: Goods },
      { path: '/allGame', component: AllGame },
      { path: '/wares', component: Wares },
      { path: '/pay', component: Pay },
      { path: '/retrieve', component: Retrieve },
      { path: '/appraisal', component: Appraisal },
      { path: '/user', component: User },
      { path: '/selectRegion', component: SelectRegion },
      { path: '/sellform', component: SellForm },
      { path: '/examine', component: examine },
      { path: '/identifyPay', component: identifyPay },
      { path: '/appraisalForm', component: AppraisalForm },
      { path: '/identResults', component: identResults },
      { path: '/autInfo', component: autInfo },
      { path: '/article', component: article },
      { path: '/order', component: order }
      // { path: '/bidSelectRegion', component: BidSelectRegion },
      // { path: '/bidForm', component: BidForm }
    ]
  },
  {
    path: '/download',
    component: download
  },
  {
    path: '/service',
    component: service
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})
export default router
