<template>
  <!-- 一级路由 -->
  <router-view v-if="isRouterActive"></router-view>
</template>
<script setup>
import { ref, nextTick, provide } from 'vue'
import { useRoute, useRouter } from 'vue-router'
const route = useRoute()
const router = useRouter()
const isRouterActive = ref(true)
provide('reload', () => {
  isRouterActive.value = false
  nextTick(() => {
    isRouterActive.value = true
  })
})
const _isMobile = () => {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  return flag
}
if (_isMobile()) {
  if (route.path != '/article') {
    console.log(route)
    console.log(route.path)
    // window.location.href = 'https://www.sybaopei.com/h5'
  }
} else {
  //alert('pc端')
}
</script>
<style lang="less" scoped>
@import './assets/font/font.css';
// 引入我们定义了less变化的文件
// ~线不能丢

// .test {
//   color: @xtxColor;
// }
::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1px;
  -webkit-box-shadow: none;
  background: #0b1a2c01;
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 1px;
  background: #0b1a2c01;
  -webkit-box-shadow: none;
}
</style>
