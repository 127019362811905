export default {
  namespaced: true,
  state: () => ({
    report: {}
  }),
  mutations: {
    reportInfo(state, report) {
      state.report = report
    }
  },
  actions: {}
}
