import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 图片懒加载
import defineDirective from '@/directive'
// 引入样式
import '@/styles/common.less'
// 测试数据
store.commit('user/setUser')
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
createApp(App).use(router).use(store).use(defineDirective).use(Antd).use(ElementPlus).mount('#app')
