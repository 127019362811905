export default {
  namespaced: true,
  state: () => ({
    game: {}
  }),
  mutations: {
    setGame(state, game) {
      state.game = game
    }
  },
  actions: {}
}
