export default {
  namespaced: true,
  state: () => ({
    loginStatus: false
  }),
  mutations: {
    loginVisible(state, loginStatus) {
      state.loginStatus = loginStatus
    }
  },
  actions: {}
}
