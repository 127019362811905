// 用户状态
export default {
  namespaced: true,
  state: () => ({
    // 个人用户信息
    userInfo: {},
    loginStatus: false //登录状态
  }),
  // 测试代码
  mutations: {
    setUser(state, info) {
      state.userInfo = info
    },
    setLoginStatus(state, val) {
      state.loginStatus = val
    }
  },
  actions: {}
}
